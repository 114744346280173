import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import {
  IUpdateProfilePicture,
  IUserResponse,
} from '../models/auth/user-response.model';

@Injectable({
  providedIn: 'root',
})
export class SharedDataService {
  private profile$ = new BehaviorSubject<IUserResponse | null>(null);
  currentProfile$ = this.profile$.asObservable();

  private profileImage$ = new BehaviorSubject<string>('');
  currentProfileImage$ = this.profileImage$.asObservable();

  setProfile(profile: IUserResponse) {
    this.profile$.next(profile);
  }

  setProfileImage(profileImage: string) {
    this.profileImage$.next(profileImage);
  }
}
