import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '@irembo-andela/irembogov3-common';
import { NgModule } from '@angular/core';
import { WelcomeComponent } from './welcome.component';
import { RoleGuard } from './core/gaurds/role.guard';
import { SidebarWrapperComponent } from './modules/shared/sidebar-wrapper/sidebar-wrapper.component';

const routes: Routes = [
  {
    path: 'dashboard',
    component: WelcomeComponent,
    children: [
      {
        path: '',
        component: SidebarWrapperComponent,
        children: [
          {
            path: 'users',
            loadChildren: () =>
              import('./modules/users/users.module').then(m => m.UsersModule),
            canActivate: [AuthGuard, RoleGuard],
            data: { roles: ['ROLE_ORGANIZATION_ADMIN'] },
          },
          {
            path: 'reports',
            loadChildren: () =>
              import('./modules/reports/reports.module').then(
                m => m.ReportsModule
              ),
            canActivate: [AuthGuard, RoleGuard],
            data: { roles: ['ROLE_ORGANIZATION_ADMIN'] },
          },
          {
            path: '',
            loadChildren: () =>
              import('./modules/dashboard/dashboard.module').then(
                m => m.DashboardModule
              ),
          },
        ],
      },
    ],
    canActivate: [AuthGuard, RoleGuard],
    data: {
      roles: [
        'ROLE_ORGANIZATION_ADMIN',
        'ROLE_ORGANIZATION_EXECUTIVE',
        'ROLE_ORGANIZATION_SUPPORT',
      ],
    },
  },
  {
    path: 'services',
    component: WelcomeComponent,
    children: [
      {
        path: 'change-requests',
        component: SidebarWrapperComponent,
        loadChildren: () =>
          import('./modules/change-requests/change-requests.module').then(
            m => m.ChangeRequestsModule
          ),
        canActivate: [AuthGuard, RoleGuard],
        data: {
          roles: ['ROLE_ORGANIZATION_ADMIN', 'ROLE_ORGANIZATION_EXECUTIVE'],
        },
      },
      {
        path: 'addons',
        component: SidebarWrapperComponent,
        loadChildren: () =>
          import('./modules/services-addons/services-addons.module').then(
            m => m.ServicesAddonsModule
          ),
        canActivate: [AuthGuard, RoleGuard],
        data: {
          roles: ['ROLE_ORGANIZATION_ADMIN'],
        },
      },
      {
        path: 'slots-and-booking',
        loadChildren: () =>
          import('./modules/slots-and-booking/slot-and-booking.module').then(
            m => m.SlotAndBookingModule
          ),
        canActivate: [AuthGuard, RoleGuard],
        data: {
          roles: ['ROLE_ORGANIZATION_ADMIN'],
        },
      },
      {
        path: '',
        component: SidebarWrapperComponent,
        loadChildren: () =>
          import('./modules/services/services.module').then(
            m => m.ServicesModule
          ),
        canActivate: [AuthGuard, RoleGuard],
        data: { roles: ['ROLE_ORGANIZATION_ADMIN'] },
      },
    ],
    canActivate: [AuthGuard, RoleGuard],
    data: {
      roles: [
        'ROLE_ORGANIZATION_ADMIN',
        'ROLE_ORGANIZATION_EXECUTIVE',
        'ROLE_ORGANIZATION_SUPPORT',
      ],
    },
  },
  {
    path: 'account',
    component: WelcomeComponent,
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./modules/account/account.module').then(m => m.AccountModule),
      },
    ],
    canActivate: [AuthGuard, RoleGuard],
    data: {
      roles: [
        'ROLE_ORGANIZATION_SUPPORT',
        'ROLE_ORGANIZATION_ADMIN',
        'ROLE_ORGANIZATION_EXECUTIVE',
      ],
    },
  },
  {
    path: 'organisation-support',
    component: WelcomeComponent,
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./modules/org-support/org-support.module').then(
            m => m.OrgSupportModule
          ),
      },
    ],
    canActivate: [AuthGuard, RoleGuard],
    data: {
      roles: ['ROLE_ORGANIZATION_SUPPORT'],
    },
  },
  {
    path: 'auth',
    loadChildren: () =>
      import('./modules/auth/auth.module').then(m => m.AuthModule),
  },
  { path: '**', redirectTo: 'dashboard' },
];

@NgModule({
  declarations: [],
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
