<div class="sidebar">
  <irembogov-left-sidebar
    [sidebarItems]="sidebarItems"
    [institutions]="institutions"
    [selectedInstitution]="selectedInstitution"
    (institutionChanged)="institutionChanged($event)"
    (actionClick)="actionClick($event)">
  </irembogov-left-sidebar>
</div>

<div class="content">
  <router-outlet></router-outlet>
  <irembogov-toast aria-live="polite" aria-atomic="true"></irembogov-toast>
</div>
