import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
  ViewEncapsulation,
  forwardRef,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  ControlValueAccessor,
  FormControl,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator,
} from '@angular/forms';
import {
  NgbCalendar,
  NgbDate,
  NgbDateAdapter,
  NgbDateParserFormatter,
  NgbDateStruct,
  NgbInputDatepicker,
  NgbPeriod,
} from '@ng-bootstrap/ng-bootstrap';
import moment from 'moment';
import { Subscription } from 'rxjs';
import { IDateRangeSelectorValue } from '../../models/date-picker-date-format.types';
import { CustomDateRangeSelectorAdapterService } from '../../services/custom-date-range-selector/custom-date-range-selector-adapter.service';
import { CustomDateRangeSelectorParserFormatterService } from '../../services/custom-date-range-selector/custom-date-range-selector-parser-formatter.service';

@Component({
  selector: 'irembogov-date-range-selector',
  templateUrl: './irembo-date-range-selector.component.html',
  styleUrls: ['./irembo-date-range-selector.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => IremboDateRangeSelectorComponent),
      multi: true,
    },
    {
      provide: NgbDateAdapter,
      useClass: CustomDateRangeSelectorAdapterService,
    },
    {
      provide: NgbDateParserFormatter,
      useClass: CustomDateRangeSelectorParserFormatterService,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => IremboDateRangeSelectorComponent),
      multi: true,
    },
  ],
})
export class IremboDateRangeSelectorComponent
  implements
    ControlValueAccessor,
    OnInit,
    OnChanges,
    AfterViewInit,
    Validator,
    OnDestroy
{
  newDate = new Date();
  @Output() selectDateRange = new EventEmitter<
    IDateRangeSelectorValue | undefined
  >();
  customformControl = new FormControl();
  @Input() allowSameDateSelection = false;
  @Input() disabled = false;
  @Input() minDate: NgbDateStruct = { year: 1900, month: 1, day: 1 };
  @Input() maxDate: NgbDateStruct = {
    year: this.newDate.getFullYear() + 10,
    month: 12,
    day: 31,
  };

  @Input() startPlaceholder!: string;
  @Input() endPlaceholder!: string;
  @Input() addRemoveTimeMinDate? = '';
  @Input() addRemoveTimeMaxDate? = '';

  @Input() startDate: NgbDate | null = null;
  @Input() endDate: NgbDate | null = null;
  invalidDateEntry = false;

  hoveredDate: NgbDate | null = null;
  initialDate: IDateRangeSelectorValue | undefined;
  IremboDateParser: NgbDateParserFormatter = this.dateParser;

  private subscriptions = new Subscription();

  constructor(
    private dateParser: NgbDateParserFormatter,
    private calendar: NgbCalendar,
    private cdRef: ChangeDetectorRef
  ) {}

  @ViewChild('datepicker', { static: true }) datepicker!: NgbInputDatepicker;

  /* eslint-disable @typescript-eslint/no-unused-vars, @typescript-eslint/no-empty-function*/
  private _onChange = (value: unknown) => {};
  public _onTouch = (value: unknown) => {};
  private _onValidationChange = () => {};
  /* eslint-enable */

  //@ViewChild('dpToDate', { static: true }) dpToDate: NgControl;

  ngOnInit() {
    this.initialDate = undefined;
  }

  toggleDatePickerInput() {
    this.setInitialDate();
    this.datepicker.toggle();
  }

  private setInitialDate() {
    if (this.startDate && this.endDate) {
      this.initialDate = {
        start: this.startDate ?? undefined,
        end: this.endDate ?? undefined,
      };
      return;
    }
    this.initialDate = undefined;
  }

  ngAfterViewInit(): void {
    if (this.addRemoveTimeMinDate) {
      this.updateMinDateValue(this.addRemoveTimeMinDate);
    }

    if (this.addRemoveTimeMaxDate) {
      this.updateMaxDateValue(this.addRemoveTimeMaxDate);
    }

    if (this.customformControl.value) {
      this.customformControl.updateValueAndValidity({ emitEvent: true });
      this._onValidationChange();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.['addRemoveTimeMinDate']?.currentValue) {
      this.updateMinDateValue(changes?.['addRemoveTimeMinDate']?.currentValue);
    }

    if (changes?.['addRemoveTimeMaxDate']?.currentValue) {
      this.updateMaxDateValue(changes?.['addRemoveTimeMaxDate']?.currentValue);
    }
  }

  onDateSelection(date: NgbDate): void {
    if (!this.startDate && !this.endDate) {
      this.startDate = date;
    } else if (
      this.startDate &&
      !this.endDate &&
      date &&
      ((this.allowSameDateSelection && date.equals(this.startDate)) ||
        date.after(this.startDate))
    ) {
      this.endDate = date;
    } else {
      this.endDate = null;
      this.startDate = date;
    }
  }

  setStartDate(startDate: NgbDate | null) {
    this.startDate = startDate;
    if (startDate) {
      this.datepicker.navigateTo(startDate);
    }
  }

  setEndDate(endDate: NgbDate | null) {
    this.endDate = endDate;

    if (endDate && !this.invalidDateEntry) {
      const startMoment = moment(
        `${this.startDate?.day}/${this.startDate?.month}/${this.startDate?.year}`,
        'DD/MM/YYYY'
      );
      const endMoment = moment(
        `${this.endDate?.day}/${this.endDate?.month}/${this.endDate?.year}`,
        'DD/MM/YYYY'
      );
      const diff =
        startMoment && endMoment ? endMoment.diff(startMoment, 'days') : 0;
      if (diff > 55) {
        this.datepicker.navigateTo(endDate);
      }
    }
  }

  isHovered(date: NgbDate) {
    return (
      this.startDate &&
      !this.endDate &&
      this.hoveredDate &&
      date.after(this.startDate) &&
      date.before(this.hoveredDate)
    );
  }

  isInside(date: NgbDate) {
    return (
      this.endDate && date.after(this.startDate) && date.before(this.endDate)
    );
  }

  isRange(date: NgbDate) {
    return (
      date.equals(this.startDate) ||
      (this.endDate && date.equals(this.endDate)) ||
      this.isInside(date) ||
      this.isHovered(date)
    );
  }

  updateMaxDateValue(addRemoveTimeMaxDate: string) {
    if (addRemoveTimeMaxDate) {
      const config = addRemoveTimeMaxDate.split(':');
      const duration = Number(config[0]);
      const period = config[1] as NgbPeriod;
      if (!isNaN(duration)) {
        this.maxDate =
          duration < 0
            ? this.subtractTimeValue(period, Math.abs(duration))
            : this.addTimeValue(period, Math.abs(duration));
      }
      this._onValidationChange();
    }
  }

  updateMinDateValue(addRemoveTimeMinDate: string) {
    if (addRemoveTimeMinDate) {
      const config = addRemoveTimeMinDate.split(':');
      const duration = Number(config[0]);
      const period = config[1] as NgbPeriod;
      if (!isNaN(duration)) {
        this.minDate =
          duration < 0
            ? this.subtractTimeValue(period, Math.abs(duration))
            : this.addTimeValue(period, Math.abs(duration));
      }
      this._onValidationChange();
    }
  }

  writeValue(dateValue: IDateRangeSelectorValue | undefined): void {
    if (dateValue?.start) {
      this.startDate = dateValue.start;
    }
    if (dateValue?.end) {
      this.endDate = dateValue.end;
    }

    this._onValidationChange();
  }

  registerOnTouched(fn: (_: unknown) => void): void {
    this._onTouch = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
  registerOnChange(fn: (_: unknown) => void): void {
    this._onChange = fn;
  }
  registerOnValidatorChange?(fn: () => void): void {
    this._onValidationChange = fn;
  }

  change(event: unknown) {
    if (event instanceof Event) {
      const changeValue = (event.target as HTMLInputElement).value;
      const date: IDateRangeSelectorValue | null = changeValue
        ? <IDateRangeSelectorValue>changeValue
        : null;

      this._onChange(date);
      this._onTouch(date);
      this._onValidationChange();
    }
  }

  cancelDate() {
    if (this.initialDate?.start && this.initialDate.end) {
      this.startDate = this.initialDate?.start ?? null;
      this.endDate = this.initialDate?.end ?? null;
      this._onChange(this.initialDate);
      this._onTouch(this.initialDate);
      this.selectDateRange.emit(this.initialDate);
    } else {
      this.startDate = null;
      this.endDate = null;
      this.selectDateRange.emit(undefined);
    }
    this.datepicker.close();
  }

  saveDate() {
    if (!this.invalidDateEntry && this.startDate && this.endDate) {
      const dateRangeValue: IDateRangeSelectorValue = {
        start: this.startDate ?? undefined,
        end: this.endDate ?? undefined,
      };
      this.selectDateRange.emit(dateRangeValue);
      this._onChange(dateRangeValue);
      this._onTouch(dateRangeValue);
    } else {
      this.startDate = null;
      this.endDate = null;
      this.selectDateRange.emit(undefined);
      this._onChange(undefined);
      this._onTouch(undefined);
    }
    this.datepicker.close();
  }

  private addTimeValue(periodValue: NgbPeriod, durationValue: number): NgbDate {
    const today = this.calendar.getToday();
    if (durationValue === 0) {
      return today;
    }
    const newDate = this.calendar.getNext(today, periodValue, durationValue);
    return newDate;
  }

  private subtractTimeValue(
    periodValue: NgbPeriod,
    durationValue: number
  ): NgbDate {
    const today = this.calendar.getToday();
    if (durationValue === 0) {
      return today;
    }
    const newDate = this.calendar.getPrev(today, periodValue, durationValue);
    return newDate;
  }

  validate(): ValidationErrors | null {
    const validationErrors: ValidationErrors = {};
    this.cdRef.detectChanges();

    if (this.customformControl.errors?.['ngbDate']) {
      const errorName = Object.keys(
        this.customformControl.errors['ngbDate']
      )[0];
      validationErrors[errorName] = true;
    }

    if (this.startDate && this.endDate && this.invalidDateEntry) {
      validationErrors['invalidDateRange'] = true;
    }

    return Object.keys(validationErrors).length ? validationErrors : null;
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
