<style>
  .i-navbar {
    position: fixed;
    width: 100%;
    z-index: 1500;
  }

  .sidebar {
    position: fixed;
    margin-top: 70px;
  }

  .content {
    margin-left: 250px;
    padding: 15px;
    background-color: #ffffff;
    min-height: 100%;
    padding-top: 50px;
  }
</style>

<div class="dashboard-wrapper">
  <div class="i-navbar">
    <irembogov-navbar
      [navbarItems]="navbarItems"
      [showActiveRole]="false"
      [navbarActions]="navbarActions">
      <irembogov-navbar-profile-section
        profile
        profileLinkText="Edit Profile"
        [username]="
          (userProfile?.firstName ?? '') + ' ' + (userProfile?.lastName ?? '')
        "
        [roles]="userRoles"
        [activeRole]="currentRole?.name"
        [updatedProfile]="profileImage"
        (logout)="logoutUser()"
        (profileClick)="onProfileClick()"
        (roleChange)="roleChange($event)"
        [showDropdownUserDetails]="true"
        [showDropdownEmail]="false"
        [dropDownCustomCss]="'org-portal-dd'">
      </irembogov-navbar-profile-section>
    </irembogov-navbar>
  </div>

  <router-outlet></router-outlet>
</div>
<irembogov-toast aria-live="polite" aria-atomic="true"></irembogov-toast>
