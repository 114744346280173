<div class="calendar-footer-buttons border-top pt-3">
  <div class="footer-inputs me-2 d-flex flex-row align-items-center">
    <div class="input-group my-0 mx-2">
      <input
        class="form-control start-date rounded"
        [placeholder]="startPlaceholder"
        [formControl]="startDateControl" />
      <small class="w-100 text-danger">
        {{ getControlErrorMessage(startDateControl, 'Start Date') }} &nbsp;
      </small>
    </div>
    <span class="mb-4">-</span>
    <div class="input-group my-0 mx-2">
      <input
        class="form-control end-date rounded"
        [placeholder]="endPlaceholder"
        [formControl]="endDateControl" />
      <small class="w-100 text-danger">
        {{ getControlErrorMessage(endDateControl, 'End Date') }} &nbsp;
      </small>
    </div>
  </div>
  <div class="footer-buttons mb-4 ms-2 d-flex flex-row align-items-center">
    <button
      class="btn btn-secondary btn-sm me-1"
      (click)="cancelDate.emit(true)">
      Cancel
    </button>
    <button class="btn btn-primary btn-sm ms-1" (click)="applyDate.emit(true)">
      Apply
    </button>
  </div>
</div>
