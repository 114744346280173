import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { IAccountActivationRequest } from '../models/auth/account-activation-request.model';
import { IAccountActivationResponse } from '../models/auth/account-activation-response.model';
import { IBaseResponseBody } from '../models/http/base-response.model';
import { IOtpVerificationReponse } from '../models/auth/otp-verification-response.model';
import { IPasswordResetRequest } from '../models/auth/password-reset-request.model';
import { IPasswordResetResponse } from '../models/auth/password-reset-response.model';
import { IPasswordResetTokenRequest } from '../models/auth/password-reset-token-request.model';
import { IPasswordResetTokenResponse } from '../models/auth/password-reset-token-response.model';
import {
  IResponse,
  IUpdateProfilePicture,
  IUserResponse,
} from '../models/auth/user-response.model';
import { IUpdateUser } from '../models/auth/update-profile.model';
import { IIremboFileUpload } from '@irembo-andela/irembogov3-common';
import { IUpdatePassword } from '../models/auth/update-password-request';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(private http: HttpClient) {}

  activateAccount(request: IAccountActivationRequest) {
    const headers = new HttpHeaders({});
    const url = `${environment.apiGatewayBaseUrl}/auth/v1/user/activate-account`;
    return this.http.post<IAccountActivationResponse>(url, request, {
      headers,
    });
  }

  requestPasswordToken(request: IPasswordResetTokenRequest) {
    const headers = new HttpHeaders({});
    const url = `${environment.apiGatewayBaseUrl}/auth/v1/reset-password/send-token`;
    return this.http.post<IPasswordResetTokenResponse>(url, request, {
      headers,
    });
  }

  checkOTPForPasswordReset(otp: string, username: string) {
    const headers = new HttpHeaders({
      username,
      oneTimePassword: otp,
      userType: 'OTHER',
    });
    const url = `${environment.apiGatewayBaseUrl}/auth/v1/reset-password/check-token-validity`;
    return this.http.get<IOtpVerificationReponse>(url, { headers });
  }

  checkOTPForAccountActivation(otp: string, username: string) {
    const headers = new HttpHeaders({
      username,
      oneTimePassword: otp,
      userType: 'OTHER',
    });
    const url = `${environment.apiGatewayBaseUrl}/auth/v1/account-activation/check-token-validity`;
    return this.http.get<IOtpVerificationReponse>(url, { headers });
  }

  resendActivationTokenUsingExpiredToken(token: string, username: string) {
    const request = {
      token,
      username,
      clientId: environment.authClientId,
      userType: 'OTHER',
    };
    const url = `${environment.apiGatewayBaseUrl}/auth/v1/account-activation/resend-token`;
    return this.http.post<any>(url, request);
  }

  resendPasswordResetTokenUsingExpiredToken(token: string, username: string) {
    const request = {
      token,
      username,
      clientId: environment.authClientId,
      userType: 'OTHER',
    };
    const url = `${environment.apiGatewayBaseUrl}/auth/v1/reset-password/resend-token`;
    return this.http.post<any>(url, request);
  }

  resetPassword(request: IPasswordResetRequest) {
    const headers = new HttpHeaders({});
    const url = `${environment.apiGatewayBaseUrl}/auth/v1/reset-password`;
    return this.http.put<IPasswordResetResponse>(url, request, { headers });
  }

  getUserDetails(userId: string): Observable<IBaseResponseBody<IUserResponse>> {
    const headers = new HttpHeaders({
      userId: userId,
      userType: 'OTHER',
    });
    const url = `${environment.apiGatewayBaseUrl}/auth/v1/user/user-by-id`;
    return this.http.get<IBaseResponseBody<IUserResponse>>(url, { headers });
  }

  getAnyUserDetails(
    userId: string
  ): Observable<IBaseResponseBody<IUserResponse>> {
    const headers = new HttpHeaders({
      userId,
      userType: 'OTHER',
    });
    const url = `${environment.apiGatewayBaseUrl}/auth/v1/user/any-user-by-id`;
    return this.http.get<IBaseResponseBody<IUserResponse>>(url, { headers });
  }

  checkUserExists(
    username: string
  ): Observable<IBaseResponseBody<IUserResponse>> {
    const headers = new HttpHeaders({
      username,
      userType: 'OTHER',
    });
    const url = `${environment.apiGatewayBaseUrl}/auth/v1/user/check-user-exist`;
    return this.http.get<IBaseResponseBody<IUserResponse>>(url, { headers });
  }

  updateUser(userData: IUpdateUser) {
    const formData: FormData = new FormData();

    formData.append('fullName', userData.fullName ? userData?.fullName : '');
    formData.append('email', userData.email ? userData?.email : '');
    formData.append('clientId', userData.clientId);
    formData.append('userType', userData.userType);
    formData.append('id', userData.id);

    const headers = new HttpHeaders({});
    const url = `${environment.apiGatewayBaseUrl}/auth/v1/update-user`;
    return this.http.put<IBaseResponseBody<IUserResponse>>(url, formData, {
      headers,
    });
  }

  updateProfilePicture(fileData: IIremboFileUpload) {
    const formData: FormData = new FormData();
    const file = this.base64ToFile(
      fileData.content as string,
      fileData.fileName,
      fileData.fileType
    );
    formData.append('clientId', environment.authClientId);
    formData.append('userType', 'OTHER');
    formData.append('profilePicture', file, file.name);

    const headers = new HttpHeaders({});
    const url = `${environment.apiGatewayBaseUrl}/auth/v1/update-profile-picture`;
    return this.http.put<IBaseResponseBody<IUpdateProfilePicture>>(
      url,
      formData,
      { headers }
    );
  }

  private base64ToFile(
    base64String: string,
    fileName: string,
    mimeType: string
  ): File {
    const byteString = atob(base64String.split(',')[1]);
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([ab], { type: mimeType });
    return new File([blob], fileName, { type: mimeType });
  }

  getProfileImage(
    userId: string
  ): Observable<IResponse<IUpdateProfilePicture>> {
    const headers = new HttpHeaders({
      userId: userId,
      userType: 'OTHER',
    });
    const url = `${environment.apiGatewayBaseUrl}/auth/v1/user/profile-picture`;
    return this.http.get<IResponse<IUpdateProfilePicture>>(url, { headers });
  }

  updatePassword(requestParam: IUpdatePassword) {
    const url = `${environment.apiGatewayBaseUrl}/auth/v1/update-password`;
    return this.http.put<IResponse<Record<string, unknown>>>(url, requestParam);
  }
}
