import { Injectable } from '@angular/core';
import { TDateRangeSelectorFormatType } from '../../models/date-picker-date-format.types';
import { NgbDateAdapter, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import moment from 'moment';

@Injectable({
  providedIn: 'root',
})
export class CustomDateRangeSelectorAdapterService extends NgbDateAdapter<string> {
  adapterDateFormat: TDateRangeSelectorFormatType = 'MMM D, YYYY';

  fromModel(value: string | null): NgbDateStruct | null {
    if (!(value && typeof value === 'string')) return null;
    const dateModelMoment = moment(value, 'MMM D, Y');

    if (!dateModelMoment.isValid()) {
      return null;
    }

    return {
      day: dateModelMoment.date(),
      month: dateModelMoment.month() + 1,
      year: dateModelMoment.year(),
    };
  }

  toModel(date: NgbDateStruct | null): string | null {
    moment.parseTwoDigitYear = function (yearString) {
      return parseInt(yearString) + 2000;
    };
    const dateMoment = moment(
      `${date?.day}/${date?.month}/${date?.year}`,
      'DD/MM/YYYY'
    );

    if (!dateMoment.isValid()) {
      return '';
    }

    return dateMoment.format('MMM D, Y');
  }
}
