import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { KeycloakService } from 'keycloak-angular';

@Component({
  selector: 'irembogov-root',
  templateUrl: './app.component.html',
})
export class AppComponent {
  title = 'organization-portal';

  constructor(
    private keycloakService: KeycloakService,
    private router: Router
  ) {}

  ngOnInit() {
    this.keycloakService.isLoggedIn().then(loggedIn => {
      if (loggedIn) {
        const roles = this.keycloakService.getUserRoles();
        if (roles.includes('ROLE_ORGANIZATION_ADMIN')) {
          this.router.navigate(['/dashboard/stats']);
        } else if (roles.includes('ROLE_ORGANIZATION_SUPPORT')) {
          this.router.navigate(['/organisation-support/applications']);
        }
      }
    });
  }
}
