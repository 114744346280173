export const environment = {
  production: false,
  idleTimeOutDuration: 1800,
  idleTimeOutCountDown: 30,
  authServiceUrl: 'https://hub-keycloak.uat.irembolabs.com',
  authRealm: 'irembo',
  authClientId: 'organization-management-portal',
  authSuccessRedirectUrl: 'https://organization-portal.uat.irembolabs.com',
  authorizationServiceBaseUrl: 'https://auth-engine.uat.irembolabs.com/auth',
  apiGatewayBaseUrl: 'https://organization-gateway.uat.irembolabs.com',
  citizenPortalUrl: 'https://citizen-portal.uat.irembolabs.com',
};
