import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { KeycloakEventType, KeycloakService } from 'keycloak-angular';
import { KeycloakProfile } from 'keycloak-js';
import { INavbarItem, ISidebarItem } from '@irembo-andela/irembogov3-common';
import { Router } from '@angular/router';
import { NavigationActionsService } from './core/services/navigation-actions.service';
import { IRole } from './core/models/auth/role.model';
import { RoleService } from './core/services/role.service';
import { OrganizationService } from './core/services/organization.service';
import { IdleTimerService } from '@irembo-andela/irembogov3-common';
import { environment } from '../environments/environment';
import { Subject, Subscription, takeUntil } from 'rxjs';
import { SharedDataService } from './core/services/shared-data.service';
import { AuthService } from './core/services/auth.service';
import { INavbarActionItem } from '@irembo-andela/irembogov3-common';

@Component({
  selector: 'irembogov-welcome',
  templateUrl: './welcome.component.html',
  encapsulation: ViewEncapsulation.None,
})
export class WelcomeComponent implements OnInit, OnDestroy {
  userProfile?: KeycloakProfile;
  isLoggedIn?: boolean;
  private cancelSubscription$ = new Subject<void>();

  acceptedRoles: IRole[] = [
    { code: 'ROLE_ORGANIZATION_SUPPORT', name: 'Organization Support' },
    { code: 'ROLE_ORGANIZATION_ADMIN', name: 'Organization Admin' },
    { code: 'ROLE_ORGANIZATION_EXECUTIVE', name: 'Organization Executive' },
  ];

  userRoles?: string[] = [];
  currentRole?: IRole;

  navbarItems: INavbarItem[] = [];

  private subscriptions = new Subscription();

  profileImage: string | undefined;

  navbarActions: INavbarActionItem[] = [
    {
      name: '',
      icon: 'icon-settings-01',
      action: 'setting',
    },
  ];

  constructor(
    private keycloak: KeycloakService,
    private router: Router,
    private navigationActionService: NavigationActionsService,
    private roleService: RoleService,
    private organizationService: OrganizationService,
    private idleTimerService: IdleTimerService,
    private sharedDataService: SharedDataService,
    private authService: AuthService
  ) {
    this.redirectUserOnTokenExpired();
  }

  ngOnInit() {
    this.subscriptions.add(
      this.roleService.currentRole.subscribe(role => {
        this.currentRole = role;
        this.getRoleNavbarItems();
      })
    );

    this.subscriptions.add(
      this.organizationService.selectedOrganization.subscribe(() => {
        this.getRoleNavbarItems();
      })
    );

    this.handleAuthentication();

    this.idleTimerService.initIdleTimer(
      environment.idleTimeOutDuration,
      environment.idleTimeOutCountDown
    );

    this.idleTimerService.idleState$.subscribe(state => {
      if (state === 'timedOut') {
        this.logoutUser();
      }
    });

    this.subscriptions.add(
      this.sharedDataService.currentProfile$.subscribe({
        next: res => {
          this.userProfile = {
            ...this.userProfile,
            firstName: res?.firstName ?? '',
            lastName: res?.lastName ?? '',
          };
        },
      })
    );

    this.subscriptions.add(
      this.sharedDataService.currentProfileImage$.subscribe({
        next: res => {
          this.profileImage = res;
        },
      })
    );
  }

  async handleAuthentication() {
    if (await this.keycloak.isLoggedIn()) {
      this.userProfile = await this.keycloak.loadUserProfile();

      if (this.userProfile.id) {
        this.getProfileImage(this.userProfile.id);
      }

      this.acceptedRoles.forEach(role => {
        if (this.keycloak.getUserRoles().includes(role.code)) {
          this.userRoles?.push(role.name);

          if (this.roleService.currentRole.value == undefined) {
            this.roleService.currentRole.next(role);
          }
        }
      });
    }
  }

  roleChange($event: string) {
    if ($event === 'Organization Support') {
      this.roleService.currentRole.next(this.acceptedRoles[0]);
    } else if ($event === 'Organization Admin') {
      this.roleService.currentRole.next(this.acceptedRoles[1]);
    } else if ($event === 'Organization Executive') {
      this.roleService.currentRole.next(this.acceptedRoles[2]);
    }
  }

  actionClick($event: ISidebarItem) {
    if ($event.routerLink !== undefined) {
      this.router.navigate([$event.routerLink]);
    } else {
      this.navigationActionService.onNavigationAction.emit($event.id);
    }
  }

  /**
   * Observers when the token is expired or token refresh process fails
   * and redirects the user to login page
   */
  redirectUserOnTokenExpired() {
    this.keycloak.keycloakEvents$
      .pipe(takeUntil(this.cancelSubscription$))
      .subscribe(event => {
        if (event.type === KeycloakEventType.OnAuthRefreshError) {
          this.keycloak.login({
            prompt: 'login',
          });
        }
      });
  }

  /**
   * Ends the session of the user
   * and redirects the user to the login page
   */
  logoutUser() {
    this.keycloak.logout().then(() => {
      this.keycloak.clearToken();
    });
  }

  getRoleNavbarItems() {
    let navbarItems: INavbarItem[] = [];

    if (this.organizationService.selectedOrganization.value === undefined) {
      this.navbarItems = navbarItems;
      return;
    }

    if (this.currentRole?.code === 'ROLE_ORGANIZATION_ADMIN') {
      navbarItems = [
        {
          name: 'Dashboard',
          routerLink: '/dashboard/stats',
          icon: 'icon-home-02',
        },
        {
          name: 'Services',
          routerLink: '/services',
          icon: 'icon-layers-three-01 icon-v-align',
        },
        {
          name: 'Users',
          routerLink: '/dashboard/users/users-list',
          icon: 'icon-users-01 icon-v-align',
        },
        {
          name: 'Reporting',
          routerLink: 'reports',
          icon: 'icon-bar-chart-01 icon-v-align',
        },
      ];
    }

    if (this.currentRole?.code === 'ROLE_ORGANIZATION_EXECUTIVE') {
      navbarItems = [
        {
          name: 'Dashboard',
          routerLink: '/dashboard/stats',
          icon: 'icon-home-02',
        },
        {
          name: 'Services',
          routerLink: '/dashboard/services/list',
          icon: 'icon-layers-three-01 icon-v-align',
        },
        {
          name: 'Reporting',
          routerLink: 'reports',
          icon: 'icon-bar-chart-01 icon-v-align',
        },
      ];
    }

    if (this.currentRole?.code === 'ROLE_ORGANIZATION_SUPPORT') {
      navbarItems = [
        {
          name: 'Applications',
          routerLink: '/organisation-support/applications',
          icon: 'icon-list icon-v-align',
        },
      ];
    }

    this.navbarItems = navbarItems;
  }

  onProfileClick() {
    this.router.navigate(['/account/profile']);
  }

  private getProfileImage(userId: string) {
    this.authService.getProfileImage(userId).subscribe({
      next: res => {
        this.profileImage = `data:${res.data.contentType};base64,${res.data.file}`;
      },
    });
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
