import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { IRole } from '../models/auth/role.model';

@Injectable({
  providedIn: 'root',
})
export class RoleService {
  currentRole: BehaviorSubject<IRole | undefined> = new BehaviorSubject<
    IRole | undefined
  >(undefined);
}
