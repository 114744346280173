import { Injectable } from '@angular/core';
import { TDateRangeSelectorFormatType } from '../../models/date-picker-date-format.types';
import {
  NgbDateParserFormatter,
  NgbDateStruct,
} from '@ng-bootstrap/ng-bootstrap';
import moment from 'moment';

@Injectable({
  providedIn: 'root',
})
export class CustomDateRangeSelectorParserFormatterService extends NgbDateParserFormatter {
  parserDateFormat: TDateRangeSelectorFormatType = 'MMM D, YYYY';

  parse(value: string): NgbDateStruct | null {
    if (!value) return null;
    const dateMoment = moment(value, 'MMM D, Y');

    if (!dateMoment.isValid()) {
      return null;
    }

    return {
      day: dateMoment.date(),
      month: dateMoment.month() + 1,
      year: dateMoment.year(),
    };
  }

  format(date: NgbDateStruct | null): string {
    moment.parseTwoDigitYear = function (yearString) {
      return parseInt(yearString) + 2000;
    };
    const dateMomentValue = moment(
      `${date?.day}/${date?.month}/${date?.year}`,
      'DD/MM/YYYY'
    );

    if (!dateMomentValue.isValid()) {
      return '';
    }

    return dateMomentValue.format('MMM D, Y');
  }
}
