import { Component, EventEmitter, Input, Output } from '@angular/core';
import {
  ISidebarItem,
  ISiderInstitutionItem,
} from '../../models/irembogov-sidebar-item.model';

@Component({
  selector: 'irembogov-left-sidebar',
  templateUrl: './irembogov-left-sidebar.component.html',
  styleUrls: ['./irembogov-left-sidebar.component.scss'],
})
export class IrembogovLeftSidebarComponent {
  @Input() sidebarItems: ISidebarItem[] = [];
  @Input() selectedInstitution: ISiderInstitutionItem | undefined;
  @Input() institutions: ISiderInstitutionItem[] | undefined;

  @Output() actionClick = new EventEmitter<ISidebarItem>();
  @Output() institutionChanged = new EventEmitter<ISiderInstitutionItem>();
}
