import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TableWrapperComponent } from './table-wrapper/table-wrapper.component';
import { CardComponent } from './card/card.component';
import { FileInputComponent } from './file-input/file-input.component';
import { UiModule } from '@irembo-andela/irembogov3-common';
import { FileInputDeletePromptModalComponent } from './file-input-delete-prompt-modal/file-input-delete-prompt-modal.component';
import { ServiceStatusChangeReasonFormComponent } from './service-status-change-reason-form/service-status-change-reason-form.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SidebarWrapperComponent } from './sidebar-wrapper/sidebar-wrapper.component';
import { RouterModule } from '@angular/router';
import { PromptModalComponent } from './prompt-modal/prompt-modal.component';
import { DueDateStatusPipe } from '../../core/pipes/due-date-status.pipe';

@NgModule({
  declarations: [
    TableWrapperComponent,
    CardComponent,
    FileInputComponent,
    FileInputDeletePromptModalComponent,
    ServiceStatusChangeReasonFormComponent,
    SidebarWrapperComponent,
    PromptModalComponent,
    DueDateStatusPipe,
  ],
  imports: [
    CommonModule,
    UiModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
  ],
  exports: [
    TableWrapperComponent,
    CardComponent,
    FileInputComponent,
    ServiceStatusChangeReasonFormComponent,
    SidebarWrapperComponent,
    DueDateStatusPipe,
  ],
})
export class SharedModule {}
