import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import {
  initializeKeycloak,
  ClientDetailsInterceptor,
  SatHeaderInterceptor,
  UiModule,
  AuthModule,
} from '@irembo-andela/irembogov3-common';
import { KeycloakAngularModule, KeycloakService } from 'keycloak-angular';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { environment } from '../environments/environment';
import { WelcomeComponent } from './welcome.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { SharedModule } from './modules/shared/shared.module';
import { OrganizationService } from './core/services/organization.service';
import { AddHeaderInterceptor } from './core/interceptors/add-header-interceptor.service';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [AppComponent, WelcomeComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    KeycloakAngularModule,
    HttpClientModule,
    SharedModule,
    UiModule,
    NgIdleKeepaliveModule.forRoot(),
    TranslateModule.forRoot(),
    AuthModule,
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: (keycloakService: KeycloakService) => {
        return initializeKeycloak(keycloakService, {
          url: environment.authServiceUrl,
          realm: environment.authRealm,
          clientId: environment.authClientId,
          redirectUri: environment.authSuccessRedirectUrl,
        });
      },
      multi: true,
      deps: [KeycloakService],
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ClientDetailsInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: SatHeaderInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AddHeaderInterceptor,
      multi: true,
    },
    {
      provide: 'environment',
      useValue: environment,
    },
    OrganizationService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
