import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
  ViewEncapsulation,
} from '@angular/core';
import {
  NgbCalendar,
  NgbDate,
  NgbDateAdapter,
  NgbDateParserFormatter,
  NgbDateStruct,
} from '@ng-bootstrap/ng-bootstrap';
import { debounceTime, Subscription } from 'rxjs';
import { CustomDateRangeSelectorAdapterService } from '../../../services/custom-date-range-selector/custom-date-range-selector-adapter.service';
import { CustomDateRangeSelectorParserFormatterService } from '../../../services/custom-date-range-selector/custom-date-range-selector-parser-formatter.service';
import { FormControl, ValidatorFn, Validators } from '@angular/forms';

@Component({
  selector: 'irembogov-date-range-selector-footer',
  templateUrl: './irembo-date-range-selector-footer.component.html',
  styleUrls: ['./irembo-date-range-selector-footer.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: NgbDateAdapter,
      useClass: CustomDateRangeSelectorAdapterService,
    },
    {
      provide: NgbDateParserFormatter,
      useClass: CustomDateRangeSelectorParserFormatterService,
    },
  ],
})
export class IremboDateRangeSelectorFooterComponent
  implements AfterViewInit, OnChanges, OnDestroy
{
  newDate = new Date();
  @Output() cancelDate = new EventEmitter<boolean>();
  @Output() applyDate = new EventEmitter<boolean>();
  @Output() setStartDate = new EventEmitter<NgbDate | null>();
  @Output() setEndDate = new EventEmitter<NgbDate | null>();
  @Output() invalidDateEntry = new EventEmitter<boolean>();

  @Input() startPlaceholder!: string;
  @Input() endPlaceholder!: string;
  @Input() addRemoveTimeMinDate? = '';
  @Input() addRemoveTimeMaxDate? = '';

  @Input() allowSameDateSelection?: boolean;

  @Input() startDate: NgbDate | null = null;
  @Input() endDate: NgbDate | null = null;

  @Input() minDate?: NgbDateStruct;
  @Input() maxDate?: NgbDateStruct;

  invalidFromDate = false;
  invalidToDate = false;

  fieldValidations: ValidatorFn[] = [
    Validators.pattern('\\S{3} \\d{1,2},\\s{0,1}\\d{4}'),
    Validators.required,
  ];

  startDateControl: FormControl = new FormControl(
    this.dateParser.format(this.startDate),
    this.fieldValidations
  );

  endDateControl: FormControl = new FormControl(
    this.dateParser.format(this.endDate),
    this.fieldValidations
  );

  private subscriptions = new Subscription();

  constructor(
    private dateParser: NgbDateParserFormatter,
    private calendar: NgbCalendar,
    private cdRef: ChangeDetectorRef
  ) {}

  IremboDateParser: NgbDateParserFormatter = this.dateParser;

  ngAfterViewInit(): void {
    this.subscriptions.add(
      this.startDateControl.valueChanges
        .pipe(debounceTime(400))
        .subscribe(() => {
          this.validateInputEntry(
            'startDate',
            this.startDate,
            this.startDateControl.value
          );
          this.setStartDate.emit(this.startDate);
        })
    );
    this.subscriptions.add(
      this.endDateControl.valueChanges.pipe(debounceTime(400)).subscribe(() => {
        this.validateInputEntry(
          'endDate',
          this.endDate,
          this.endDateControl.value
        );
        this.setEndDate.emit(this.endDate);
      })
    );
  }

  ngOnChanges(changes: SimpleChanges) {
    this.startPlaceholder =
      changes?.['startPlaceholder']?.currentValue ?? 'e.g. Jan 1, 2000';
    this.endPlaceholder =
      changes?.['endPlaceholder']?.currentValue ?? 'e.g. Dec 31, 2000';

    if (changes?.['allowSameDateSelection']) {
      this.allowSameDateSelection =
        changes?.['allowSameDateSelection']?.currentValue;
    }

    if (changes?.['minDate']) {
      this.minDate = changes?.['minDate']?.currentValue;
    }

    if (changes?.['maxDate']) {
      this.maxDate = changes?.['maxDate']?.currentValue;
    }

    if (
      changes?.['startDate']?.currentValue &&
      JSON.stringify(changes?.['startDate']?.previousValue) !==
        JSON.stringify(changes?.['startDate']?.currentValue)
    ) {
      this.startDateControl.setValue(this.dateParser.format(this.startDate));
    }
    if (
      changes?.['endDate']?.currentValue &&
      JSON.stringify(changes?.['endDate']?.previousValue) !==
        JSON.stringify(changes?.['endDate']?.currentValue)
    ) {
      this.endDateControl.setValue(this.dateParser.format(this.endDate));
    }
  }

  validateInputEntry(
    type: 'startDate' | 'endDate',
    currentValue: NgbDate | null,
    inputValue: string
  ): void {
    const parsedValue = this.dateParser.parse(inputValue);
    let ngbDateValue: NgbDate | null = null;
    let invalidInput = false;
    if (parsedValue && this.calendar.isValid(NgbDate.from(parsedValue))) {
      ngbDateValue = NgbDate.from(parsedValue);
    } else {
      ngbDateValue = currentValue;
      invalidInput = true;
    }

    if (type === 'startDate') {
      this.startDate = ngbDateValue;
    }

    if (type === 'endDate') {
      this.endDate = ngbDateValue;
    }

    this.checkDateControls(type, invalidInput);

    this.invalidDateEntry.emit(
      this.startDateControl.invalid || this.endDateControl.invalid
    );
  }

  checkDateControls(type: 'startDate' | 'endDate', invalidInput: boolean) {
    if (type === 'startDate' && invalidInput) {
      this.startDateControl.setErrors({
        ...this.startDateControl.errors,
        invalidDateInput: true,
      });
    } else {
      delete this.startDateControl?.errors?.['invalidDateInput'];
    }

    if (type === 'endDate' && invalidInput) {
      this.endDateControl.setErrors({
        ...this.endDateControl.errors,
        invalidDateInput: true,
      });
    } else {
      delete this.endDateControl?.errors?.['invalidDateInput'];
    }

    if (!this.allowSameDateSelection && this.startDate?.equals(this.endDate)) {
      this.endDateControl.setErrors({
        ...this.endDateControl.errors,
        endDateEqualStartDate: true,
      });
    } else {
      delete this.endDateControl?.errors?.['endDateEqualStartDate'];
      this.endDateControl.updateValueAndValidity({ emitEvent: false });
    }

    if (this.endDate?.before(this.startDate)) {
      this.endDateControl.setErrors({
        ...this.endDateControl.errors,
        endDateBeforeStartDate: true,
      });
    } else {
      delete this.endDateControl?.errors?.['endDateBeforeStartDate'];
      this.endDateControl.updateValueAndValidity({ emitEvent: false });
    }

    if (this.minDate && this.startDate?.before(this.minDate)) {
      this.startDateControl.setErrors({
        ...this.startDateControl.errors,
        minDate: true,
      });
    } else {
      delete this.startDateControl?.errors?.['minDate'];
      this.startDateControl.updateValueAndValidity({ emitEvent: false });
    }

    if (this.maxDate && this.endDate?.after(this.maxDate)) {
      this.endDateControl.setErrors({
        ...this.endDateControl.errors,
        maxDate: true,
      });
    } else {
      delete this.endDateControl?.errors?.['maxDate'];
      this.endDateControl.updateValueAndValidity({ emitEvent: false });
    }
  }

  getControlErrorMessage(control: FormControl, label: string): string | null {
    if (!(control.dirty || control.touched)) {
      return null;
    }
    if (control.errors?.['required']) {
      return `${label} is required.`;
    }

    if (control.errors?.['pattern']) {
      return `Enter date like Jan 1, 2000.`;
    }

    if (control.errors?.['invalidDateInput']) {
      return `Enter a valid date.`;
    }

    if (control.errors?.['endDateEqualStartDate']) {
      return `End date can not be same as Start date`;
    }

    if (control.errors?.['endDateBeforeStartDate']) {
      return `End date should be after Start date.`;
    }

    if (control.errors?.['minDate']) {
      return `Start can not be before ${this.minDate?.day}-${this.minDate?.month}-${this.minDate?.year}`;
    }

    if (control.errors?.['maxDate']) {
      return `End can not be after ${this.maxDate?.day}-${this.maxDate?.month}-${this.maxDate?.year}`;
    }

    return null;
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
