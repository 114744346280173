import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {
  ISidebarItem,
  ISiderInstitutionItem,
} from '@irembo-andela/irembogov3-common';
import { IOrganizationPublic } from '../../../core/models/organization/organization-public-response.model';
import { NavigationActionsService } from '../../../core/services/navigation-actions.service';
import { OrganizationService } from '../../../core/services/organization.service';
import { DomSanitizer } from '@angular/platform-browser';
import { Subscription } from 'rxjs';

@Component({
  selector: 'irembogov-sidebar-wrapper',
  templateUrl: './sidebar-wrapper.component.html',
  styleUrls: ['./sidebar-wrapper.component.scss'],
})
export class SidebarWrapperComponent implements OnInit, OnDestroy {
  sidebarItems: ISidebarItem[] = [];
  private sidebarItemsSubscription = new Subscription();

  organizationsLoading = false;
  rowOrganizations!: IOrganizationPublic[];

  institutions: ISiderInstitutionItem[] = [];

  selectedInstitution!: ISiderInstitutionItem;

  constructor(
    private organizationService: OrganizationService,
    private router: Router,
    private navigationActionService: NavigationActionsService,
    private sanitizer: DomSanitizer,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.sidebarItemsSubscription =
      this.navigationActionService.sidenavItems.subscribe(items => {
        this.sidebarItems = [...items];
        this.cdr.detectChanges();
      });

    this.organizationService.loadedOrganizations.subscribe(orgs => {
      this.initOrganizations(orgs);
    });

    this.organizationService.selectedOrganization.subscribe(org => {
      if (org !== undefined) {
        const siderBarInstitution: ISiderInstitutionItem = {
          name: org.name,
          code: org.acronym,
          image: this.sanitizer.bypassSecurityTrustUrl(org.logo) as string,
        };

        this.selectedInstitution = siderBarInstitution;
      }
    });
  }

  actionClick($event: ISidebarItem) {
    if ($event.routerLink !== undefined) {
      this.router.navigate([$event.routerLink]);
    } else {
      this.navigationActionService.onNavigationAction.emit($event.id);
    }
  }

  institutionChanged($event: ISiderInstitutionItem) {
    this.selectedInstitution = $event;
    const selectedOrg = this.rowOrganizations.filter(
      o => o.acronym === $event.code
    )[0];
    if (selectedOrg !== this.organizationService.selectedOrganization.value) {
      this.organizationService.selectedOrganization.next(selectedOrg);
    }
  }

  initOrganizations(organizations: IOrganizationPublic[]) {
    this.rowOrganizations = [...organizations];
    this.institutions = [];

    this.rowOrganizations.forEach(element => {
      const item: ISiderInstitutionItem = {
        name: element.acronym,
        code: element.acronym,
        image: this.sanitizer.bypassSecurityTrustUrl(element.logo) as string,
      };

      this.institutions.push(item);
    });
  }
  ngOnDestroy(): void {
    if (this.sidebarItemsSubscription) {
      this.sidebarItemsSubscription.unsubscribe();
    }
  }
}
