import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import {
  BehaviorSubject,
  Observable,
  catchError,
  forkJoin,
  map,
  of,
  switchMap,
} from 'rxjs';
import { HttpPagedResponse } from '../models/http/http-paged-response.model';
import { ToastService } from '@irembo-andela/irembogov3-common';
import { KeycloakService } from 'keycloak-angular';
import { IOrganizationUserCreationResponse } from '../models/organization/organization-user-creation-response.model';
import { IOrganizationUserCreationRequest } from '../models/organization/organization-user-creation-request.model';
import { IOrganizationUserResponse } from '../models/organization/organization-user-response.model';
import { IBaseResponseBody } from '../models/http/base-response.model';
import { HttpUnpagedResponse } from '../models/http/http-unpaged-response.model';
import { AuthService } from './auth.service';
import { IOrganizationPublic } from '../models/organization/organization-public-response.model';
import { UnassignRoleRequest } from '../models/auth/unassign-role-request.model';
import { IUserByRolesAndScopeResponse } from '../models/auth/user-by-roles-and-scope-response.model';

@Injectable({
  providedIn: 'root',
})
export class OrganizationService {
  selectedOrganization: BehaviorSubject<IOrganizationPublic | undefined> =
    new BehaviorSubject<IOrganizationPublic | undefined>(undefined);
  loadedOrganizations: BehaviorSubject<IOrganizationPublic[]> =
    new BehaviorSubject<IOrganizationPublic[]>([]);

  constructor(
    private http: HttpClient,
    private keyCloakService: KeycloakService,
    private toastService: ToastService,
    private authService: AuthService
  ) {
    this.handleAuth();
  }

  private async handleAuth() {
    if (await this.keyCloakService.isLoggedIn()) {
      const profile = await this.keyCloakService.loadUserProfile(true);
      this.getOrganizationByUser(profile.id);
    }
  }

  getOrganizationByUser(userId?: string) {
    this.authService.getUserDetails(userId ?? '').subscribe({
      next: value => {
        const scopeIds: string[] = [];

        value.data.userRoles.forEach(role => {
          if (
            role.scopeType === 'ORGANIZATION' &&
            role.scopeId !== null &&
            !scopeIds.includes(role.scopeId)
          ) {
            scopeIds.push(role.scopeId);
          }
        });

        const organizations: IOrganizationPublic[] = [];

        if (scopeIds.length > 0) {
          const logoRequests = scopeIds.map(element => {
            return this.getOrganizationById(element).pipe(
              switchMap(organization => {
                return this.getImageUrlFromBlob(organization.data.logo).pipe(
                  map(imageUrl => {
                    return { ...organization.data, logo: imageUrl };
                  }),
                  catchError(() => {
                    return of({ ...organization.data, logo: '' });
                  })
                );
              })
            );
          });

          forkJoin(logoRequests).subscribe({
            next: (orgsWithLogos: IOrganizationPublic[]) => {
              organizations.push(...orgsWithLogos);
              if (!this.selectedOrganization.value) {
                this.selectedOrganization.next(orgsWithLogos[0]);
              }
              this.loadedOrganizations.next(organizations);
            },
            error: error => {
              console.error('Error fetching organizations:', error);
            },
          });
        }
      },
      error: err => {
        this.toastService.show({ body: err.error.message, type: 'error' });
      },
    });
  }

  getImageUrlFromBlob(fileName: string): Observable<string> {
    return new Observable<string>(observer => {
      this.getOrganizationLogo(fileName).subscribe({
        next: (blob: Blob) => {
          const imageUrl = URL.createObjectURL(blob);
          observer.next(imageUrl);
          observer.complete();
        },
        error: error => {
          observer.error(error);
        },
      });
    });
  }

  getOrganizationById(
    organizationId: string
  ): Observable<IBaseResponseBody<IOrganizationPublic>> {
    const url = `${environment.apiGatewayBaseUrl}/admin/v1/public/organizations/${organizationId}`;
    return this.http.get<IBaseResponseBody<IOrganizationPublic>>(url);
  }

  createOrganizationUser(
    userRequest: IOrganizationUserCreationRequest,
    roleName: string
  ): Observable<HttpUnpagedResponse<IOrganizationUserCreationResponse>> {
    const organizationId = this.selectedOrganization.value?.id;
    const role: any = {
      role: roleName,
      scopeType: 'ORGANIZATION',
      scopeId: `${organizationId}`,
    };
    userRequest.roles.push(role);
    userRequest.clientId = environment.authClientId;
    const url = `${environment.apiGatewayBaseUrl}/auth/v1/create-organization-user`;
    return this.http.post<
      HttpUnpagedResponse<IOrganizationUserCreationResponse>
    >(url, userRequest);
  }

  getOrganizationUsers(
    page: number,
    pageSize: number,
    role: string,
    searchTerm: string
  ): Observable<HttpPagedResponse<IUserByRolesAndScopeResponse>> {
    const organizationId = this.selectedOrganization.value?.id;
    const url = `${
      environment.apiGatewayBaseUrl
    }/auth/v1/user-role/by-role-and-scope?size=${pageSize}&page=${page}&${
      searchTerm ? 'searchTerm=' + searchTerm : ''
    }`;
    const DEFAULT_ROLES =
      'ROLE_ORGANIZATION_ADMIN,ROLE_ORGANIZATION_EXECUTIVE,ROLE_ORGANIZATION_SUPPORT';
    const headers = new HttpHeaders({
      roles: role ? role : DEFAULT_ROLES,
      scopeId: organizationId ?? '',
      scopeType: 'ORGANIZATION',
    });
    return this.http.get<HttpPagedResponse<IUserByRolesAndScopeResponse>>(url, {
      headers,
    });
  }

  deactivateOrganizationUser(
    userId: string,
    roleName: string
  ): Observable<IBaseResponseBody<IOrganizationUserResponse>> {
    const unassignRoleRequest: UnassignRoleRequest = {
      userId,
      roleName,
      clientId: environment.authClientId,
      userType: 'OTHER',
    };
    const url = `${environment.apiGatewayBaseUrl}/auth/v1/user/unassign-role`;
    return this.http.patch<IBaseResponseBody<IOrganizationUserResponse>>(
      url,
      unassignRoleRequest
    );
  }

  getOrganizationLogo(imageName: string): Observable<Blob> {
    const url = `${environment.apiGatewayBaseUrl}/admin/v1/file-manager?fileName=${imageName}`;
    return this.http.get(url, { responseType: 'blob' });
  }
}
