import { EventEmitter, Injectable } from '@angular/core';
import { ISidebarItem } from '@irembo-andela/irembogov3-common';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class NavigationActionsService {
  onNavigationAction = new EventEmitter<string>();
  sidenavItems: BehaviorSubject<ISidebarItem[]> = new BehaviorSubject<
    ISidebarItem[]
  >([]);
}
